import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
    <Layout>
        <SEO title="Privacy Policy"/>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1>Privacy Policy</h1>
                </div>
                  <p>Your privacy is important to us. We respect your privacy regarding any information we may collect from you via the software Application Karma Hunter ("Application")</p>


                  <h3>Information we collect</h3>
                  <p>
                  This Privacy Policy document contains types of information that is collected and recorded by Karma Hunter and how we use it.

                  If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.

                  This Privacy Policy applies only to our online activities and is valid for visitors to our Application with regards to the information that they shared and/or collect in Karma Hunter.
                  The Application obtains your email address, as well as the list of subreddits and queries you generated. It does not collect any Personally Identifiable Information beyond that. Your email address will only be stored for authentication purposes.
                </p>

                  <h3>How we use your information</h3>
                  <p>We use the information we collect in various ways, including to:

                  Provide, operate, and maintain our Application
                  Improve, personalize, and expand our Application
                  Understand and analyze how you use our Application
                  Develop new products, services, features, and functionalities
                  Find and prevent fraud</p>
                  <h3>Log Files</h3>
                  <p>Karma Hunter follows a standard procedure of using log files. These files log visitors when they access the Application. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and timestamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the Application, tracking users' movement on the Application, and gathering demographic information. Our Privacy Policy was created with the help of the Privacy Policy Generator and the Online Privacy Policy Generator.</p>

                  <h3>Children's Information</h3>
                  <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.

                  Karma Hunter does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our Application, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>

                  <h3>CCPA Privacy Rights (Do Not Sell My Personal Information)</h3>
                  <p>Under the CCPA, among other rights, California consumers have the right to:</p>

                  <p>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>

                  <p>Request that a business delete any personal data about the consumer that a business has collected.</p>

                  <p>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>

                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

                  <h3>GDPR Data Protection Rights</h3>
                    <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>

                    <p>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>

                    <p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>

                    <p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>

                    <p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>

                    <p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>

                    <p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>

                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>



                  <h3>Changes</h3>
                    <p>This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy here. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.</p>

                  <h3>Contact Us</h3>
                  <p>
                    If you have any questions regarding privacy or terms, or have questions about our practices, please contact us via email at myproductlauncherteam@gmail.com
                  </p>


            </div>
        </div>
    </Layout>
)

export default PrivacyPage
